a.link {
border: none;
position: absolute;
bottom: 1rem;
width: auto;
right: 1rem;
}
  #dark-light-container > div {
      float: left;
      box-sizing: border-box;
      position: relative;
      padding: 2rem;
      width: 50%;
      text-align: center;

  }
  .white-container { background: white; }
  .black-container { background: black; }

  .three-d-card {
      box-shadow: none;
      backface-visibility: visible;
      background: transparent;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
      transform-style: preserve-3d;
      padding: 0;
      height: auto;
      margin: 2rem 2rem 4rem 2rem;
      width: 24rem;
      height: 32rem;
      float: left;
      transition: all 0.2s ease-out;
      border: none;
      letter-spacing: 1px;
  }
  .flip, .unflip {
      background: #161616;
      font-size: 1rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0.5rem 0.75rem;
      border-radius: 100px;
      line-height: 1rem;
      cursor: pointer;
      transition: all 0.1s ease-out;
  }
.unflip {
top: auto;
background: #161616;
bottom: 1rem;
}
  .flip:hover {
    background: rgba(0,0,0,0.3);
  }
  .inner-three-d-card-backface {
      transform: rotateX(0) rotateY(0deg) scale(1) translateZ(-4px);
    border-radius: 14px;
    background: linear-gradient(45deg, #fafafa, #fafafa);
    position: absolute;
    top: 0;
    color: white;
    padding: 2rem;
    box-sizing: border-box;
    transition: all 0.15s ease-out;
      will-change: transform, filter;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .three-d-card.flipped {
    transform: rotateY(180deg);
  }
  .three-d-card .flip-inner-three-d-card {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    padding: 2rem 1.5rem;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    height: 100%;
 }
    .flip-inner-three-d-card h3 {
      font-size: 1.25rem;
      margin-top: 0;
    }
    .flip-inner-three-d-card p {
      color: #161616;
      font-weight: 400;
      line-height: 1.5rem;
    }
  .three-d-card .inner-three-d-card {
      font-size: 2rem;
      color: white;
      padding: 1rem 2rem;
      line-height: 3rem;
      will-change: transform, filter;
      float: none;
      background: #2c3e4b;
      background-size: calc(100% + 6px) auto;
      background-position: -3px -3px;
      margin: 0;
      transition: all 0.15s ease-out;
      height: auto;
      border-radius: 14px;
      box-sizing: border-box;
      overflow: hidden;
      display: block;
      margin: 0px auto;
      transform: rotateX(0deg) rotateY(0deg) scale(1);
      top: 0;
      height: 100%;
      filter: drop-shadow(0 15px 15px rgba(0,0,0,0.3));
      font-weight: 500;
      perspective-origin: 0 0;
      letter-spacing: 0;
  }
.three-d-card .glare {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
transition: all 0.1s ease-out;
opacity: 0.6;
pointer-events: none;
height: 100%;
border-radius: 14px;
z-index: 9999;
mix-blend-mode: hard-light;
background: radial-gradient(circle at 50% 50%, rgb(199 198 243), transparent); 
}

.three-d-card .top-section {
background: linear-gradient(45deg, #11998e, #38ef7d);
position: absolute;
top: 0;
left: 0;
border-radius: 14px 14px 0 0;
width: 100%;
height: 35%;
z-index: 99;
}

.user-2 .top-section {
background: linear-gradient(45deg, #11998e, #38ef7d);
}
.three-d-card .user-icon {
position: absolute;
left: calc(50% - 64px);
top: calc(35% - 72px);
z-index: 99;
}
.three-d-card .user-icon img {
width: 128px;
height: 128px;
box-shadow: 0 4px 9px rgba(0,0,0,0.2);
border-radius: 100px;
transform: scale(1);
transition: all 0.1s ease-out;
}

.three-d-card .user-icon img:hover {
transform: scale(1.08);
}
.three-d-card .glare {
opacity: 0;
}
.three-d-card.animated .glare {
opacity: 0.3;
}

.three-d-card.animated.user .glare {
opacity: 0.15;
}

.three-d-card .name {
color: white;
font-size: 1.5rem;
}

.three-d-card .bottom-section {
position: absolute;
top: 35%;
left: 0;
background: #161616;
width: 100%;
height: 65%;
box-sizing: border-box;
padding-top: 64px;
text-align: center;
}

.bottom-section .area {
font-size: 1rem;
opacity: 0.4;
display: block;
line-height: 1rem;
}
.buttons {
float: left;
width:  calc(100% - 2rem);
position: absolute;
bottom: 1.5rem;
display: flex;
align-items: center;
justify-content: center;
left: 1rem;
}
.buttons > a {    
  border: none;
  width: auto;
  background: #2d2d62;
  padding: 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1rem;
  transition: all 0.1s ease-out;
  transform: scale(1);
  padding: 0.75rem 1rem;
  margin: 0 1rem 0 0;
  border-radius: 100px;
  text-decoration: none;
}
.buttons .main {
background: #3d26e7;
}
.buttons > .main:hover {
background: #614df1;
}
.buttons > a:hover {
background: #494989;
}
.buttons .message svg {
margin: 0 0.5rem 0 0;
}
.buttons .message {
margin: 0;
}
  @media screen and (max-width: 1000px) {
      #dark-light-container > div {
          width: 100%;
      }
  }

.timeline {
  height: 100%;
}

.rb-container {
  font-family: "PT Sans", sans-serif;
  width: 80%;
  margin: auto;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 2.5em 0;
  padding: 0;
  display: inline-block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border:0px;
  border-left: 1px solid #2c3e4b;
  padding: 0 0 50px 30px;
  position: relative;
  transition: all 200ms ease-in-out;
}

.rb-container ul.rb li:hover {
  background: rgba(44, 62, 75, 0.05);
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -10px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(44, 62, 75, 1);
  border-radius: 500%;
  background: #32c384;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #32c384;
  transition: all 200ms ease-in-out;
}

ul.rb li .timestamp {
  color: #32c384;
  position: relative;
  width: 100px;
  font-size: 12px;
}

.item-title {
  color: #161616;
}