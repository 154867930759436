.img-overlay-wrap {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
  transition: transform 150ms ease-in-out;
}

.img-overlay-wrap img { /* <= optional, for responsiveness */
   display: block;
   max-width: 100%;
   height: auto;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index:1;
}

.content-container-h {
  height: 100%;
  position: relative;
  background-color: white;
  z-index: 2;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-text {
  font-size: 12px;
  margin: 5px;
  text-decoration: none;
}

.text-container {
  width:85%;
}

.text-names {
  margin:5px;
  font-size: 20px;
}

.info-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width:85%;
  margin-top: 2px;
  margin-bottom: 8px;
  border-radius: 10px;
  padding:12px;
  padding-bottom: 5px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.Card {
  height: 100vh;
}
