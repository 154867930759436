*,
*::before,
*::after {
    box-sizing: border-box;
    word-wrap: break-word;
}

:root {
  --whitesmoke: #FAFAFA;
  --gray: #161616;
  --lightgray:#444444;
  --white: #fff;
  --darkslategray: #2c3e4b;
  --mediumseagreen: #32c384;
  --cornflowerblue: #3276c3;
  --mediumsealightgreen: #11998e;
  --lightgreensake: #38ef7d;
  --neutralred: #f22424;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--mediumseagreen);
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  display: block;
  font-size: 1.25rem;
  text-decoration: none;
  }

html,
body,
input,
select,
p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--gray);
    scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-color:white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  font-weight: 200;
  letter-spacing: 0px;
  color: var(--darkslategray);
}

h1 {
  font-size: 3em; /* 48px */
  line-height: 56px;
}

h2 {
  font-size: 2.5em; /* 40px */
  line-height: 48px;
}

h3 {
  font-size: 1.75em; /* 28px */
  line-height: 40px;
}

h4 {
  font-size: 1.5em; /* 24px */
  line-height: 32px;
}

h5 {
  font-size: 1.25em; /* 20px */
  line-height: 28px;
}

h6 {
  font-size: 1em; /* 16px */
  line-height: 24px;
}

/* Phone font-sizes */

@media ( max-width: 766px ) {
  html,
  body,
  input,
  select {
      font-size: 16px;
      line-height: 18px;
  }

  h1 {
      font-size: 2em; /* 32px */
      line-height: 1.25em;
  }

  h2 {
      font-size: 1.625em; /* 26px */
      line-height: 1.15384615em;
  }

  h3 {
      font-size: 1.375em; /* 22px */
      line-height: 1.13636364em;
  }

  h4 {
      font-size: 1.25em; /* 20px */
      line-height: 1.11636364em;
  }

  h5 {
      font-size: 1.125em; /* 18px */
      line-height: 1.09636364em;
  }

  h6 {
      font-size: 1em; /* 16px */
      line-height: 1.07636364em;
  }
}

/* Buttons */

.btn-normal {
  font-size: 1em;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

.btn-small {
  font-size: 0.875em;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

.btn-rounded-green {
  padding: 6px 18px;
  color: #fff;
  background: var(--darkslategray);
  border: 1px solid var(--darkslategray);
  border-radius: 10px;
  transition: all .2s ease-in-out;
}

.btn-rounded-green:hover {
  background: #fff;
  color: var(--mediumseagreen);
  border: 1px solid var(--mediumseagreen);
  border-radius: 16px;
  transform: scale(1.04);
}

.btn-rounded-red {
  padding: 6px 18px;
  color: #fff;
  background: var(--neutralred);
  border: 1px solid var(--neutralred);
  border-radius: 10px;
  transition: all .2s ease-in-out;
}

.btn-rounded-red:hover {
  background: #fff;
  color: var(--neutralred);
  border: 1px solid var(--neutralred);
  border-radius: 16px;
  transform: scale(1.04);
}

/* Navigation bar util*/

.navbar {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  background: var(--white);
}

.navbar-brand > img {
  height: 60px;
  margin-left:80px;
}

@media (min-width: 992px){
  .navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: 100% !important;
  }
}

a.nav-link.topbar-link {
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  line-height: 28px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 var(--mediumseagreen);
  color: var(--mediumseagreen);
  font-size: 1rem;
  font-weight: 600;
  padding: 0 0.25rem;
  margin: 0 0.25rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

a.nav-link.topbar-link:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 var(--mediumseagreen);
}

a.nav-link.topbar-link.active {
  color: #fff !important;
  box-shadow: inset 200px 0 0 0 var(--mediumseagreen);
}

/* Containers */

.elevated-container {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  background: var(--whitesmoke);
  border-radius: 10px;
  display:inline-block;
  position:relative;
  width:65%;
}

@media (max-width: 992px) {
  .elevated-container {
    width:85%;
  }
}

/* Inputs */

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--darkslategray);
  outline: 0;
  font-size: 1.1rem;
  color: var(--darkslategray);
  padding: 7px 0;
  background: transparent;
  transition: border-image border-image-slice  0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.8rem;
  color: var(--darkslategray);
}

.form__field:focus {
  padding-bottom: 7px;
  font-weight: 500;
  border-width: 2px;
  border-image: linear-gradient(to right, var(--mediumsealightgreen), var(--lightgreensake));
  border-image-slice: 1;
  transition: all 0.2s;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: all 0.2s;
  font-size: 0.8rem;
  color: var(--mediumsealightgreen);
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}


/* Loading */

.loading {
  position: absolute;
  right:10px;
  top:30px;
  display: inline-block;
  margin-left: 5px;
}

/* Profile Navigation Tabs */

.nav-tabs .nav-link.active {
  border-radius: 8px !important;
  border:0px;
  color: var(--white);
  background-color: var(--mediumseagreen);
}

.nav-tabs .nav-link {
  padding:6px 16px;
  margin: 0px 5px;
  color:var(--gray);
  border:0px;
  background-color: var(--whitesmoke);
}

.nav-tabs {
  border-bottom: 0px;
}

ul.nav.nav-pills > li a {
  color:var(--whitesmoke);
}

ul.nav.nav-pills > li a.active {
  color:var(--mediumseagreen);
  background-color: var(--gray);
}

ul.nav.nav-pills > li {
  background-color: var(--gray);
  width:100%;
}

ul.nav.nav-pills > li:hover a {
  color: var(--mediumseagreen);
}

.admin-sidebar
{
  background-color: var(--gray);
}