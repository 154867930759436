.btn-rounded-admin {
    padding: 6px 18px;
    color: #fff;
    background: #32c384;
    border: 2px solid #32c384;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}

.btn-rounded-admin.active {
  padding: 6px 18px;
  color: #fff;
  background: #11998e;
  border: 2px solid #11998e;
  border-radius: 8px;
  transition: all .2s ease-in-out;
}

.btn-rounded-admin:hover {
  color: #fff;
  background: #11998e;
  border: 2px solid #11998e;
}

.btn-rounded-admin-grey {
    padding: 6px 18px;
    color: #fff;
    background: #161616;
    border: 2px solid #161616;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}

.btn-rounded-admin-grey:hover {
  color: #fff;
  background: #2c3e4b;
  border: 2px solid #2c3e4b;
}

.btn-table {
  font-size: 1em;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  padding: 10px 28px;
  color: #fff;
  background: var(--darkslategray);
  border: 1px solid var(--darkslategray);
  border-radius: 10px; 
  transition: all .2s ease-in-out;
}

.btn-table:hover {
  background: #fff;
  color: var(--mediumseagreen);
  border: 1px solid var(--mediumseagreen);
  border-radius: 16px;
  transform: scale(1.04);
}